import React from 'react';
import './App.css';
import AssymetricOperations from './components/AssymetricOperations';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <AssymetricOperations />
      </header>
    </div>
  );
}

export default App;
